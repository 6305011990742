@import '../../styles/mixins';


  .logo {
    max-width: 500px;
    margin-top: 150px;
  }

  .artwork {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fef0ea+0,fdac93+100 */
    background: #fef0ea; /* Old browsers */
    background: -moz-linear-gradient(top,  #fef0ea 0%, #fdac93 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #fef0ea 0%,#fdac93 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #fef0ea 0%,#fdac93 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fef0ea', endColorstr='#fdac93',GradientType=0 ); /* IE6-9 */

  }

