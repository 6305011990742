@import '../../styles/mixins';

.Popover-body {
  display: inline-flex;
  flex-direction: column;
  padding: 2rem 4rem;
  background: hsl(0, 0%, 27%);
  color: white;
  border-radius: 0.3rem;
}

.Popover-tipShape {
  fill: hsl(0, 0%, 27%);
}
