@import '../../styles/mixins';

  .menu-logout {
    margin-bottom: 20px!important;
  }

  .settings-menu
  {
      background: #969696;
  }

  .settings-button
  {
    padding-left: 20px!important;
    width:100%;
  }
