@import '../../styles/mixins';

  .hair-styles-image {
    border-radius:15px
  }

  .hair-styles-column {
    text-align: start;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    justify-content: center!important;
    button {
      height: 32px;
      max-height: 32px;
    }
  }
