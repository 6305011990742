@import '../../styles/mixins';

.dashboard-services-menu {

}
  .service-image {
    border-radius:15px
  }

  .service-column {
    text-align: start;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    justify-content: center!important;
    button {
      height: 32px;
      max-height: 32px;
    }
  }